import { CURRENT_YEAR, START_YEAR_OPTIONS } from "@shared/config/Date";
import { YearOption } from "./types";

export function generateYearsOptions(
    generations: { startedAt: number | null; endedAt: number | null }[]
): YearOption[] {
    let minYear: number | null = null;
    let maxYear: number | null = null;

    generations.forEach(({ startedAt, endedAt }) => {
        if (typeof startedAt === "number") {
            minYear = minYear === null ? startedAt : Math.min(minYear, startedAt);
        }
        if (typeof endedAt === "number") {
            maxYear = maxYear === null ? endedAt : Math.max(maxYear, endedAt);
        }
    });

    minYear = minYear === null ? START_YEAR_OPTIONS : minYear;
    maxYear = maxYear === null ? CURRENT_YEAR : maxYear;

    if (minYear > maxYear) {
        minYear = START_YEAR_OPTIONS;
        maxYear = CURRENT_YEAR;
    }

    const years: YearOption[] = [];
    for (let y = maxYear; y >= minYear; y--) {
        years.push({ label: y.toString(), value: y.toString() });
    }

    return years;
}