import dynamic from "next/dynamic";
import { PrimaryButtonProps } from "./types";
import classes from "./PrimaryButton.module.scss";

const MotionButton = dynamic(() => import("framer-motion").then((mod) => mod.motion.button), {
    ssr: false
});

export const PrimaryButton = (props: PrimaryButtonProps) => {
    return (
        <MotionButton
            disabled={props.isDisabled}
            onClick={props.onClick}
            style={{
                ...props.style
            }}
            className={`${classes.PrimaryButton} ${props.type ? classes[props.type] : null} ${props.className} ${props.isLoading ? classes.Loading : null}`}
        >
            {props.isLoading && <div className={classes.Loader} />}

            {!!props.icon && props.icon}
            {props.content}
        </MotionButton>
    );
};