import React, { useEffect } from "react";
import { digitalKeyBoardPattern, numberWithSpaces } from "../../../../utils/utils";
import { TextInputProps } from "./types";
import classes from "./TextInput.module.scss";

const TextInput = ({ className, type: inType = "text", ...props }: TextInputProps) => {
    let value = props.value || "";
    if (inType === "number") {
        value = numberWithSpaces(value);
    }

    const [type, setType] = React.useState(inType || "text");

    useEffect(() => {
        if (inType === "number") {
            setType("text");
        } else {
            setType(inType);
        }
    }, [inType]);
    return (
        <div className={classes.TextInput} style={props.contStyle}>
            <input
                className={className}
                value={value || ""}
                disabled={props.disabled}
                style={props.style}
                placeholder={props.placeholder}
                type={type}
                onChange={(e) => {
                    if (props.onChange) {
                        if (inType === "number") {
                            const val = e.target.value;
                            if (val === "") {
                                props.onChange(undefined);
                            } else {
                                const res = val.replace(/\D/g, "");
                                props.onChange(res ? parseInt(res) : undefined);
                            }
                        } else {
                            const val = e.target.value;
                            props.onChange(val);
                        }
                    }
                }}
                pattern={inType === "number" ? digitalKeyBoardPattern : undefined}
                inputMode={inType === "number" ? "numeric" : undefined}
                id={props.id}
                maxLength={props.maxLength}
            />
        </div>
    );
};
export default TextInput;