import { useState, useEffect, useRef } from "react";

export const useScroll = () => {
    const [scrollY, setScrollY] = useState(
        typeof window !== "undefined" ? window.scrollY || document.documentElement.scrollTop : 0
    );
    const callbacks = useRef<((value: number) => void)[]>([]);

    useEffect(() => {
        if (typeof window === "undefined") return;

        const handleScroll = () => {
            const y = window.scrollY || document.documentElement.scrollTop;
            setScrollY(y);

            callbacks.current.forEach((callback) => callback(y));
        };

        window.addEventListener("scroll", handleScroll);

        handleScroll();

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return {
        scrollY: {
            get: () => scrollY,
            onChange: (callback: (value: number) => void) => {
                callbacks.current.push(callback);

                callbacks.current = Array.from(new Set(callbacks.current));

                return () => {
                    callbacks.current = callbacks.current.filter((cb) => cb !== callback);
                };
            }
        }
    };
};