export const PLATFORMS = [
    { value: 1, label: "Дром" },
    { value: 2, label: "Авито" },
    { value: 3, label: "Авто.ру" },
    { value: 6, label: "Aster.kz" },
    { value: 7, label: "Mycar.kz" },
    { value: 8, label: "Kolesa.kz" },
    { value: 9, label: "Mobile.de" },
    { value: 10, label: "Av.by" },
    { value: 11, label: "Uremont.com" },
    { value: 12, label: "Migtorg.com" }
];