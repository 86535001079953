import { FetchBaseQueryError, createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
    ApiChartResponse,
    ApiChartResponseArray,
    ApiChartResponseArraySchema,
    PriceRangeResponse,
    PriceRangeResponseSchema
} from "../../features/charts/scheme";
import { baseQuery } from "./query";
import { String2StringObj } from "../../utils/utils";
import { DateTime } from "luxon";
import { TimeSort } from "../../components/Features/Catalog/catalogUtils";
import { CarDetails } from "@components/Features/Analytics/CarToEsitmate/types";

const API = process.env.NEXT_PUBLIC_API_URL;

export const analyticsApi = createApi({
    reducerPath: "analyticsApi",
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers) => {
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getAnalyticsForOffer: builder.query<ApiChartResponse[], { id: string; period: string }>({
            query: (args) => ({
                url: `${API}/v1/analytics/offers/${args.id}/price_change?interval=${
                    args.period === TimeSort.Week ? "week" : "month"
                }`,
                method: "GET"
            })
        }),
        getOfferAnalyticsBounds: builder.query<PriceRangeResponse, string>({
            query: (id) => ({
                url: `${API}/v1/analytics/offers/${id}/price_range`,
                method: "GET"
            }),
            transformResponse: (response: PriceRangeResponse) => {
                const parsed = PriceRangeResponseSchema.parse(response);
                return parsed;
            }
        }),

        getPriceRangeForCharacteristics: builder.query<PriceRangeResponse, CarDetails>({
            query: (filters) => {
                const converted = {
                    brandUuid: filters.brandUuid,
                    modelUuid: filters.modelUuid,
                    generationUuid: filters.generationUuid,
                    displacement: filters.engineDisplacement,
                    prepBodyType: filters.bodyType,
                    prepEngineType: filters.engineType,
                    prepDriveType: filters.driveType,
                    prepTransmission: filters.transmission,
                    prepPts: filters.pts,
                    mileage: filters.mileage,
                    ownersCount: filters.onwersQnt
                };

                return {
                    url: `${API}/v1/analytics/price_range`,
                    method: "GET",
                    params: converted
                };
            },
            transformResponse: (response: PriceRangeResponse) => {
                const parsed = PriceRangeResponseSchema.parse(response);
                return parsed;
            }
        }),

        getPriceChangeForCharacteristics: builder.query<
            ApiChartResponseArray[],
            PriceChangeForCharacteristicsArgs
        >({
            query: (args) => {
                const { filters, interval } = args;
                const converted = {
                    brandUuid: filters.brandUuid,
                    modelUuid: filters.modelUuid,
                    generationUuid: filters.generationUuid,
                    displacement: filters.engineDisplacement,
                    bodyType: filters.bodyType,
                    engineType: filters.engineType,
                    driveType: filters.driveType,
                    transmission: filters.transmission,
                    prepPts: filters.pts,
                    mileage: filters.mileage,
                    ownersCount: filters.onwersQnt
                };

                return {
                    url: `${API}/v1/analytics/price_change?interval=${
                        interval === TimeSort.Week ? "week" : "month"
                    }`,
                    method: "GET",
                    params: converted
                };
            }
        }),

        getAnalyticsForCategory: builder.query<ApiChartResponseArray, String2StringObj>({
            // @ts-ignore
            queryFn: async (filters, api, extraOptions) => {
                return {
                    data: [
                        { date: "2023-08-21", avgPrice: 4_500_000 },
                        { date: "2023-08-28", avgPrice: 4_500_000 },
                        { date: "2023-09-04", avgPrice: 4_500_000 },
                        { date: "2023-09-11", avgPrice: 4_500_000 },
                        { date: "2023-09-18", avgPrice: 4_500_000 },
                        { date: "2023-09-25", avgPrice: 3_500_000 },
                        { date: "2023-10-02", avgPrice: 4_100_000 },
                        { date: "2023-10-09", avgPrice: 4_200_000 },
                        { date: "2023-10-16", avgPrice: 4_500_000 }
                    ]
                        .map((el) => {
                            const date = DateTime.fromISO(el.date).setLocale("ru");
                            const label = date.toFormat("LLL d");
                            return {
                                date: label,
                                avgPrice: Math.round(el.avgPrice / 1000)
                            } as ApiChartResponse;
                        })
                        .slice(-8)
                };
                const result: any = await baseQuery(
                    {
                        headers: {
                            "Content-Type": "application/json"
                        },
                        url: `${API}/v1/analytics/price_change/`,
                        method: "GET",
                        params: filters
                    },
                    api,
                    extraOptions
                );

                const data = result?.data?.data;

                try {
                    const parsedData = ApiChartResponseArraySchema.parse(data);
                    return parsedData
                        ? {
                              data: parsedData
                          }
                        : { error: result.error as FetchBaseQueryError };
                } catch (e) {
                    return { error: e as FetchBaseQueryError };
                }
            }
        })
    })
});

interface PriceChangeForCharacteristicsArgs {
    filters: CarDetails;
    interval: TimeSort;
}

export const {
    useGetAnalyticsForOfferQuery,
    useGetAnalyticsForCategoryQuery,
    useGetOfferAnalyticsBoundsQuery,
    useGetPriceRangeForCharacteristicsQuery,
    useGetPriceChangeForCharacteristicsQuery
} = analyticsApi;

export default analyticsApi.reducer;