import React from "react";
import Image from "next/image";
import Link from "next/link";
import classes from "./AnalyticsBanner.module.scss";

const AnalyticsBanner = () => {
    return (
        <Link href={`/analytics`} className={classes.Link}>
            <div className={classes.AnalyticsBanner}>
                <div className={classes.TwoLeftBlocks}>
                    <div className={classes.BlockWithSvg}>
                        <div className={classes.Pic1Cont}>
                            <svg
                                width="60"
                                height="75"
                                viewBox="0 0 60 75"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M33 58H26V92H33V58Z" fill="url(#paint0_linear_3202_910)" />
                                <path d="M33 58H26V65H33V58Z" fill="#BDD6E0" />
                                <circle
                                    cx="30"
                                    cy="30"
                                    r="27.3889"
                                    fill="#406DC1"
                                    stroke="white"
                                    strokeWidth="5.22222"
                                />
                                <path
                                    d="M23.9197 41V20H32.3285C35.0146 20 37.1265 20.6 38.6642 21.8C40.2214 22.98 41 24.73 41 27.05C41 29.35 40.2214 31.1 38.6642 32.3C37.1265 33.48 35.0146 34.07 32.3285 34.07H26.0219L27.7153 32.27V41H23.9197ZM27.7153 32.69L26.0219 30.92H32.2993C33.8564 30.92 35.0535 30.59 35.8905 29.93C36.747 29.25 37.1752 28.3 37.1752 27.08C37.1752 25.86 36.747 24.93 35.8905 24.29C35.0535 23.63 33.8564 23.3 32.2993 23.3H26.0219L27.7153 21.38V32.69ZM21 37.88V35.78H34.1387V37.88H21Z"
                                    fill="white"
                                />
                                <circle cx="30" cy="30" r="29.5" stroke="#D0D5DE" />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_3202_910"
                                        x1="26"
                                        y1="75.5354"
                                        x2="33"
                                        y2="75.5354"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stopColor="#E6EBF4" />
                                        <stop offset="0.381479" stopColor="white" />
                                        <stop offset="0.666667" stopColor="white" />
                                        <stop offset="1" stopColor="#E6EBF4" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>

                        <div className={classes.Text}>Узнайте, сколько стоит автомобиль</div>
                    </div>

                    <div className={classes.Pic2Cont}>
                        <Image alt="best cars" src="/3cars.png" width={260} height={90} />
                    </div>
                </div>
                <div className={classes.ButtonBlock}>
                    <button className={classes.Button}>Оценка автомобиля</button>
                </div>
            </div>
        </Link>
    );
};
export default AnalyticsBanner;