import dynamic from "next/dynamic";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import Snowfall from "react-snowfall";
import classNames from "classnames";
import { AppState } from "@app/store/store";
import { setMinHeight } from "@app/store/layoutSlice";
import { TemplateProps } from "@app/Template/TemplateProps";
import { Header, Footer, footerClasses, headerClasses } from "@widgets";
import { ScrollButton } from "src/widgets/ScrollButton";
import { TechnicalPause } from "@features";
import { CookieConsent } from "@features/cookie-consent/CookieConsent";
import { useCopyWithSource } from "@features/copy-with-source";
import CallbackForm from "@features/callback-form/callback-form-button";
import classes from "./Template.module.scss";

const DynamicToast = dynamic(() => import("@shared/ui/Toast").then((mod) => mod.Toast), {
    ssr: false
});
const Modals = dynamic(() => import("@components/Features/Modals/Modals"), { ssr: false });

const Template: React.FC<React.PropsWithChildren<TemplateProps>> = ({ children, bgColor }) => {
    const dispatch = useDispatch();

    const [minContentHeight, setMinContentHeight] = useState(0);

    const isOnTechnicalPause = useSelector((state: AppState) => state.layout.technicalPause);
    const footerVisible = useSelector((state: AppState) => state.layout.footerVisible);
    const containerClasses = classNames(classes.Container, {
        [classes.Container__blue]: bgColor === "blue",
        [classes.Container__white]: bgColor === "white"
    });

    const callbackFormVisible = useSelector((state: AppState) => state.layout.callbackFormVisible);

    const calculateHeights = () => {
        const headerEl: HTMLElement | null = document.querySelector(`.${headerClasses.Header}`);
        const footerEl: HTMLElement | null = document.querySelector(`.${footerClasses.Footer}`);
        const windowHeight = window.innerHeight;

        if (headerEl && (footerEl || !footerVisible)) {
            const newMinContentHeight = footerVisible
                ? windowHeight - headerEl.offsetHeight - (footerEl?.offsetHeight || 0)
                : windowHeight - headerEl.offsetHeight;
            setMinContentHeight(newMinContentHeight);
            dispatch(setMinHeight(newMinContentHeight));
        }
    };

    const [isClient, setIsClient] = useState(false);
    useEffect(() => {
        if (typeof window !== "undefined") {
            setIsClient(true);
        } else {
            setIsClient(false);
        }
    }, []);

    useEffect(() => {
        calculateHeights();
    }, [footerVisible]);

    useEffect(() => {
        window.addEventListener("resize", calculateHeights, true);

        return () => {
            window.removeEventListener("resize", calculateHeights);
        };
    }, [footerVisible]);

    useCopyWithSource();

    return (
        <main className={`${classes.Template}`}>
            <Modals />
            <Header />
            <div
                className={containerClasses}
                style={{
                    minHeight: minContentHeight || 1000
                }}
            >
                {isOnTechnicalPause ? (
                    <TechnicalPause minContentHeight={minContentHeight} />
                ) : (
                    children
                )}
            </div>
            {footerVisible && <Footer />}
            {isClient && <DynamicToast />}
            {!callbackFormVisible && <CallbackForm />}
            <ScrollButton />
            <CookieConsent />
            <Snowfall color="white" snowflakeCount={81} />
        </main>
    );
};

export default Template;