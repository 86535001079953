import { createApi } from "@reduxjs/toolkit/query/react";
import { toast } from "react-toastify";
import { baseQueryWithReauth } from "@shared/api/baseQueryWithReauth";
import { configApi } from "@shared/lib/configApi";
import { SendSupportCarsFormArgs } from "./types";
import { CARS_FORM_MESSAGES } from "./constants";

export const supportApi = createApi({
    reducerPath: "supportApi",
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        sendSupportCarsForm: builder.mutation<void, SendSupportCarsFormArgs>({
            query: (data) => ({
                url: `${configApi.API_URL}/v1/feedback/cars`,
                method: "POST",
                body: JSON.stringify(data)
            }),
            async onQueryStarted(_, { queryFulfilled }) {
                try {
                    await queryFulfilled;
                    toast.success(CARS_FORM_MESSAGES.SUCCESS, { autoClose: 3000 });
                } catch (error) {
                    toast.error(CARS_FORM_MESSAGES.ERROR, {
                        autoClose: 3000
                    });
                }
            }
        })
    })
});

export const { useSendSupportCarsFormMutation } = supportApi;