import React, { FC } from "react";
import { IconProps } from "@shared/ui/Icon/ui/types";

export const BreadcrumbsArrow: FC<IconProps> = ({ className }) => (
    <svg
        className={className}
        width="6"
        height="10"
        viewBox="0 0 6 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.505025 0.494975C0.778392 0.221608 1.22161 0.221608 1.49497 0.494975L5.45477 4.45477C5.72814 4.72814 5.72814 5.17135 5.45477 5.44472L1.49498 9.40452C1.22161 9.67789 0.778393 9.67789 0.505026 9.40452C0.231659 9.13115 0.231659 8.68794 0.505026 8.41457L3.96985 4.94975L0.505025 1.48492C0.231659 1.21156 0.231659 0.768342 0.505025 0.494975Z"
            fill="#747F94"
        />
    </svg>
);