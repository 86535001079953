import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Select from "react-select";
import MobileSelectInput from "../MobileSelectInput/MobileSelectInput";
import { useWindowSize } from "@src/hooks/useWindowsSize";
import { createPortal } from "react-dom";
import { Option, SelectInputProps } from "./types";
import classes from "./SelectInput.module.scss";

const SelectInput = ({
    options,
    placeholder,
    formatOptionLabel,
    className,
    isClearable = false,
    isLoading = false,
    disabled = false,
    menuPlacement = "bottom",
    listHeight,
    components = undefined,
    isSearchable = true,
    isMulti = false,
    title,
    ariaLabel,
    instanceId,
    isExpanded = false,
    ...props
}: SelectInputProps) => {
    const inputFontCss = {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 13,
        color: "#7B869B",
        ...props.fontStyles
    };

    const borderColor = props.borderColor || "rgba(181, 188, 203, 0.33)";
    const borderRadius = props?.borderRadius || 8;

    const [isShowMobileSelect, setIsShowMobileSelect] = React.useState(false);

    const size = useWindowSize();
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        if (size.width) {
            if (size.width < 600) {
                setIsMobile(true);
            } else {
                setIsMobile(false);
            }
        }
    }, [size]);

    const portalEl =
        typeof document !== "undefined" ? document.getElementById("portal-root") : null;

    return (
        <>
            {isShowMobileSelect &&
                portalEl &&
                createPortal(
                    <MobileSelectInput
                        isImageSelect={instanceId === "generation-auto"}
                        instanceId={instanceId}
                        title={title || "фильтр"}
                        options={options}
                        value={isMulti ? (props.value as Option[]) : props.value}
                        isMulti={isMulti}
                        onChange={(selectedOptions) => {
                            props.onChange?.(selectedOptions);
                        }}
                        onClose={() => {
                            setIsShowMobileSelect(false);
                        }}
                    />,
                    portalEl
                )}
            <div
                className={classNames("SelectInput", classes["select-input"], className, {
                    [classes["select-input__disabled"]]: disabled
                })}
                style={{
                    marginBottom: props.marginBottom ? props.marginBottom : undefined
                }}
            >
                <Select
                    isSearchable={isMobile ? false : isSearchable}
                    isMulti={isMulti}
                    onMenuOpen={() => {
                        if (isMobile) {
                            setIsShowMobileSelect(true);
                        }
                    }}
                    menuIsOpen={isMobile ? false : undefined}
                    instanceId={instanceId}
                    options={options}
                    placeholder={placeholder}
                    formatOptionLabel={formatOptionLabel}
                    aria-label={ariaLabel || placeholder}
                    value={props.value || (isMulti ? [] : null)}
                    onChange={(selectedOptions) => {
                        props.onChange?.(selectedOptions || (isMulti ? [] : null));
                    }}
                    isLoading={isLoading}
                    isClearable={isClearable}
                    menuPlacement={menuPlacement}
                    isDisabled={disabled}
                    defaultMenuIsOpen={isExpanded}
                    menuShouldScrollIntoView={true}
                    components={components}
                    classNames={{
                        multiValue: (d) => `select-multivalue our-multivalue-${d.data.value}`
                    }}
                    styles={{
                        menu: (base) => ({ ...base, zIndex: 99999 }),
                        menuList: (base) => ({ ...base, height: listHeight }),
                        control: (baseStyles) => {
                            return {
                                ...baseStyles,
                                borderRadius: borderRadius,
                                cursor: "pointer",
                                minHeight: props?.height || 36,
                                height: "auto",
                                backgroundColor: "transparent",
                                borderColor: borderColor,
                                "&:hover": {
                                    border: `1px solid ${borderColor}`,
                                    borderColor: borderColor,
                                    boxShadow: "unset"
                                }
                            };
                        },
                        option: (styles) => {
                            return {
                                ...styles,
                                overflowY: "scroll",
                                overflowX: "hidden"
                            };
                        },

                        indicatorSeparator: (baseStyles) => {
                            return {
                                ...baseStyles,
                                border: "none",
                                backgroundColor: "transparent"
                            };
                        },
                        container: (baseStyles) => {
                            return {
                                ...baseStyles,
                                width: props?.width || 200,
                                height: "auto",
                                minHeight: props?.height || 36
                            };
                        },
                        singleValue: (baseStyles) => {
                            return {
                                ...baseStyles,
                                ...inputFontCss
                            };
                        },
                        multiValue: (baseStyles) => {
                            return {
                                ...baseStyles,
                                ...inputFontCss,
                                borderRadius: "6px"
                            };
                        },
                        multiValueLabel: (baseStyles) => ({
                            ...baseStyles,
                            cursor: "default"
                        }),
                        multiValueRemove: (baseStyles) => ({
                            ...baseStyles,
                            cursor: "pointer",
                            borderRadius: "6px"
                        }),
                        placeholder: (baseStyles) => {
                            return {
                                ...baseStyles,
                                ...inputFontCss
                            };
                        },
                        valueContainer: (baseStyles) => {
                            return {
                                ...baseStyles,
                                paddingLeft: 16,
                                ...props.valueContainerStyles
                            };
                        },
                        dropdownIndicator: (base) => ({
                            ...base,
                            color: "#1D1D1B"
                        })
                    }}
                />
            </div>
        </>
    );
};
export default SelectInput;